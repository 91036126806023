import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  dateLocales,
  landingLangStrings,
  LANGUAGES_LIST,
  zoopPolicyHost,
} from "constants/";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { goToDashboard } from "utils/auth";

export function useIsMobile() {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down("lg"));
}

export function useBreakpoint(value) {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(value));
}

export function useRedirectAfterAuth(defaultPath = "/") {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isCreator = searchParams.get("mode") === "creator";
  let redirect = useCallback(
    function () {
      if (isCreator) goToDashboard({ pathname: "/" });
      const from = localStorage.getItem("from") || defaultPath;
      localStorage.removeItem("from");
      navigate(from, { replace: true });
    },
    [navigate]
  );
  return redirect;
}

export function useDidUpdateEffect(effect, deps) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      return effect();
    }
  }, deps);
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", _.debounce(handleResize, 200));
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
export function useLocale() {
  const user = useSelector((state) => state.users.entities);
  return dateLocales[user?.language || LANGUAGES_LIST.english];
}

export const usePolicyHostUrl = () => {
  const user = useSelector((state) => state.users.entities);

  let hostUrl = useCallback(
    (page) => {
      const langFormatted =
        landingLangStrings[user.language] ||
        landingLangStrings[LANGUAGES_LIST.english];
      return `${zoopPolicyHost}/${langFormatted}/${page}`;
    },
    [user.language]
  );

  return hostUrl;
};
export const useStripeFormAppearance = () => {
  const theme = useTheme();
  return {
    theme: "flat",
    variables: {
      colorPrimary: theme.palette.accent.main,
      colorBackground: theme.palette.surface.main,
      colorText: theme.palette.textNeutralGray800,
      colorDanger: theme.palette.coral.main,
      fontFamily: theme.typography.fontFamily,
      spacingUnit: "4px",
      borderRadius: "8px",
    },
    rules: {
      ".Error": {
        color: theme.palette.error.main,
      },
    },
  };
};
