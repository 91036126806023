import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDropHistory,
  getDrops,
  getDropStatus as getDrop,
} from "services/dropsApi";

import { EDITION_STATUSES } from "../constants";
import { EVENTS } from "../constants";
import {
  getRewardMessages,
  postRewardMessage,
} from "../services/api/rewardsApi";
import { getMarketplaceCards } from "../services/discoverApi";

export const fetchDrops = createAsyncThunk(
  "drops/fetchDrops",
  async (payload, isAuth) => {
    const response = isAuth
      ? await getMarketplaceCards(payload)
      : await getDrops(payload, isAuth);
    return response;
  }
);

export const fetchDrop = createAsyncThunk(
  "drops/fetchDrop",
  async (id, isAuth) => {
    const response = await getDrop(id, isAuth);
    return response;
  }
);

export const fetchDropHistory = createAsyncThunk(
  "drops/fetchHistory",
  async (id) => {
    const response = await getDropHistory(id);
    return response;
  }
);

export const fetchLimitedEdition = createAsyncThunk(
  "drops/fetchLimitedEdition",
  async (payload) => {
    const response = await getMarketplaceCards(payload);
    return response;
  }
);

export const fetchLimitedEditionRewardMessages = createAsyncThunk(
  "drops/fetchLimitedEditionRewardMessages",
  async (payload) => {
    const response = await getRewardMessages(payload);
    return response?.data;
  }
);

export const postLimitedEditionRewardMessage = createAsyncThunk(
  "drops/postLimitedEditionRewardMessage",
  async (payload) => {
    const response = await postRewardMessage(payload);
    return response;
  }
);

const initialState = {
  drops: {
    0: [],
    1: [],
    2: [],
  },
  status: "idle",
  error: null,
  isLastPage: false,
  drop: null,
  dropLoading: null,
  dropStatus: "idle",
  history: {
    entities: [],
    loading: false,
  },
  limitedEditionCards: [],
  limitedEditionCardsTotal: 0,
  checkoutUrl: null,
  /*
    currentLimitedEditionReward: {
      0: {
        loading: false,
        messages: [],
        order: {},
      },
    },
  */
  currentLimitedEditionReward: {},
};

export const dropsSlice = createSlice({
  name: "drops",
  initialState,
  reducers: {
    setCheckoutUrl(state, action) {
      const { checkoutUrl } = action.payload || {};
      state.checkoutUrl = checkoutUrl;
    },
    reminderSwitched(state, action) {
      const id = action.payload;
      const existingDrop = state.drops[0].find((drop) => drop.id === id);
      if (existingDrop) {
        existingDrop.reminderState = !existingDrop.reminderState;
      }
      if (state.drop) {
        state.drop.reminderState = !state.drop.reminderState;
      }
    },
    resetDrops: () => {
      return initialState;
    },
    resetDrop: (state) => {
      state.drop = null;
      state.dropStatus = "idle";
    },
    updateDrop: (state, action) => {
      const { payload } = action;
      if (payload.event === EVENTS.BUY) {
        const phase = payload.status.split("droppingStage")[1];
        const index = state.drops[phase].findIndex(
          (drop) => drop.id === parseInt(payload.cardId)
        );
        if (state.drops[phase][index]) {
          state.drops[phase][index] = {
            ...state.drops[phase][index],
            ...payload,
          };
        }
      }

      if (state.drop?.id === parseInt(payload.cardId)) {
        state.drop = {
          ...state.drop,
          ...payload,
        };
      }
    },
    addRewardMessage: (state, action) => {
      const { rewardOrderId } = action.payload;
      if (state.currentLimitedEditionReward[+rewardOrderId]?.messages) {
        state.currentLimitedEditionReward[+rewardOrderId].messages.push(
          action.payload
        );
      } else {
        state.currentLimitedEditionReward[+rewardOrderId].messages = [
          action.payload,
        ];
      }
    },
    resetCurrentLimitedEditionReward: (state) => {
      state.currentLimitedEditionReward = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDrops.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDrops.fulfilled, (state, action) => {
        const phaseArg = action?.meta?.arg?.phase;
        const page = action?.meta?.arg?.page || 1;
        if (page === 1) {
          state.drops[phaseArg] = action.payload?.cards;
        } else {
          state.drops[phaseArg] = [
            ...state.drops[phaseArg],
            ...action.payload?.cards,
          ];
        }

        state.status = "succeeded";

        state.isLastPage =
          action.payload?.length < action?.meta?.arg?.params?.limit;
      })
      .addCase(fetchDrops.rejected, (state, action) => {
        state.status = "failed";
        state.isLastPage = true;
        state.error = action.error.message;
      })
      .addCase(fetchLimitedEdition.fulfilled, (state, action) => {
        const { page } = action.meta.arg;
        const { cards, total } = action.payload;
        if (cards.length) {
          if (page === 1 || !page) {
            state.limitedEditionCards = cards;
          } else {
            state.limitedEditionCards = [
              ...state.limitedEditionCards,
              ...cards,
            ];
          }
          state.limitedEditionCardsTotal = total;
        }
      })
      .addCase(fetchDrop.fulfilled, (state, action) => {
        state.drop = action.payload;
        state.dropStatus = "succeeded";
        state.dropLoading = false;
      })
      .addCase(fetchDrop.pending, (state, action) => {
        state.dropStatus = "loading";
        state.dropLoading = true;
      })
      .addCase(fetchDrop.rejected, (state, action) => {
        state.dropStatus = "failed";
        state.dropLoading = false;
      })
      .addCase(fetchDropHistory.fulfilled, (state, action) => {
        state.history.entities = action.payload;
        state.history.loading = false;
      })
      .addCase(fetchDropHistory.pending, (state, action) => {
        state.history.loading = true;
      })
      .addCase(fetchDropHistory.rejected, (state, action) => {
        state.history.loading = false;
      })
      .addCase(fetchLimitedEditionRewardMessages.pending, (state, action) => {
        const { orderId } = action.meta.arg;
        if (!state.currentLimitedEditionReward[orderId]) {
          state.currentLimitedEditionReward[orderId] = {};
        }
        state.currentLimitedEditionReward[orderId].loading = true;
      })
      .addCase(fetchLimitedEditionRewardMessages.fulfilled, (state, action) => {
        const { orderId } = action.meta.arg;
        state.currentLimitedEditionReward[orderId].loading = false;
        state.currentLimitedEditionReward[orderId].messages =
          action.payload.messages;
        state.currentLimitedEditionReward[orderId].order = action.payload.order;
      })
      .addCase(fetchLimitedEditionRewardMessages.rejected, (state, action) => {
        const { orderId } = action.meta.arg;
        state.currentLimitedEditionReward[orderId].loading = false;
      })
      .addCase(postLimitedEditionRewardMessage.fulfilled, (state) => {});
  },
});

export const {
  reminderSwitched,
  resetDrops,
  resetDrop,
  updateDrop,
  setCheckoutUrl,
  addRewardMessage,
  resetCurrentLimitedEditionReward,
} = dropsSlice.actions;

export default dropsSlice.reducer;

export const selectAllDrops = (state) => state.drops.drops;
export const selectDropsByPhase = (state, phase) => state.drops.drops[phase];

export const selectDropById = (state, dropId) =>
  state.drops.drops.find((drop) => drop.id === dropId);
export const isDropFinished = (state) =>
  state.drops.drop?.status === EDITION_STATUSES.complete;
