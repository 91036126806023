import { enGB, es, fr, ja, ko, pt, tr, zhCN } from "date-fns/locale";

export const dropsLimit = 25;
export const bidsCount = 500;

export const PLAYING_FEED_VIDEO = "playing-feed-video";

export const celebrityDomain =
  process.env.REACT_APP_LOCAL === "local"
    ? "http://localhost:3001"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://staging.admin.zoop.club"
    : "https://admin.zoop.club";

export const mainAppDomain =
  process.env.REACT_APP_LOCAL === "local"
    ? "http://localhost:3000"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://staging.zoop.club"
    : "https://app.zoop.club";

export const telegramBotName =
  process.env.REACT_APP_ENV === "staging" ? "ZoopStageBot" : "ZoopStageBot";

export const EID_STATUS = {
  MATCH: "match",
  NOMATCH: "nomatch",
};

export const DOC_STATUS = {
  MATCH: "match",
  NOMATCH: "nomatch",
  WAITING: "waiting",
};

export const MODAL_TYPE = {
  VERIFY: "verify",
  VERIFY_2: "verify_2",
  MODAL: "modal",
  USERS: "users",
  CHALLENGE: "challenge achieved",
  BLOCK_USER: "block user",
  WITH_DYNAMIC_CONTENT: "WITH_DYNAMIC_CONTENT",
  BARTER_MATCH: "barter match",
  BARTER_TUTS: "BARTER_TUTS",
  POST_IMAGE: "POST_IMAGE",
  SUBSCRIBE_CREATOR_SUCCESS_MODAL: "SUBSCRIBE_CREATOR_SUCCESS_MODAL",
  STRIPE_SUCCESS_MODAL: "STRIPE_SUCCESS_MODAL",
  ONBOARDING_TUTORIAL: "ONBOARDING_TUTORIAL",
  REPORT_MODAL: "REPORT_MODAL",
  DONATE_MODAL: "DONATE_MODAL",
  SUBSCRIBE_CREATOR: "SUBSCRIBE_CREATOR",
};

export const EDITION_STATUSES = {
  phase0: "droppingStage0",
  phase2: "droppingStage2",
  complete: "complete",
  notReleased: "notReleased",
};

export const PLATFORM = {
  WEB: "WEB",
};

export const DROP_INITIAL_PRICE = "10";

export const TRANSFER_STATUSES = {
  pending: "pending",
  placed: "placed",
  processed: "processed",
  succeeded: "succeeded",
  processing: "processing",
  cancelled: "cancelled",
  rejected: "rejected",
  success: "success",
  approved: "approved",
  reversed: "reversed",
  returned: "returned",
  unpaid: "unpaid",
};

export const WALLET_TRANSACTION_STATUS = {
  created: "created",
  pending: "pending",
  inProgress: "inProgress",
  pendingPayment: "pendingPayment",
  failed: "failed",
  declined: "declined",
  processed: "processed",
  complete: "complete",
  cancelled: "cancelled",
};

export const ORDER_TYPES = {
  buy: "buy",
  sell: "sell",
  drop: "drop",
};

export const TRANSACTIONS_STATUSES = {
  executed: "executed",
  cancelled: "cancelled",
  placed: "placed",
};

export const TRANSACTIONS_LOAD_LIMIT = 25;

export const PAYMENT_METHODS_FILTERS = {
  DEPOSIT: "deposit",
  MARKET: "market",
  DROP: "drop",
};

export const SWAP_STATUSES = {
  pending: "pending",
  approved: "approved",
  cancelled: "cancelled",
  rejected: "rejected",
};

export const SWAP_MODAL_TYPES = {
  success: "success",
  error: "error",
};

export const SWAPS_TYPE = {
  incoming: "incoming",
  outgoing: "outgoing",
};

export const CONNECTION_REQUEST_TYPE = {
  incoming: "incoming",
  outgoing: "outgoing",
};

export const SCROLL_THRESHOLD = "150px";

export const SWAPS_LOAD_LIMIT = 25;

export const ANIMATION_DIRECTION = {
  forward: 1,
  backward: -1,
};

export const SWAP_CARDS_LIMIT = 3;

export const TABS_LIST = {
  all: "All",
  inProgress: "In progress",
  completed: "Completed",
};

export const CONNECTIONS_LOAD_LIMIT = 25;

export const CONNECTIONS_TABS = {
  friends: 0,
  following: 1,
};

export const ACCOUNT_STATES = {
  default: 0,
  username: 1,
  personalInformation: 2,
  address: 3,
  // email: 4,
  // language: 4,
  blockedAccounts: 5,
};

export const LANGUAGES_LIST = {
  english: "en-GB",
  spanish: "es-ES",
  portugues: "pt-BR",
  french: "fr-FR",
  turkish: "tr-TR",
  korean: "ko-KR",
  japanese: "ja-JP",
  chinese: "zh-CN",
  chineseTraditional: "zh-TW",
};

export const dateLocales = {
  "en-GB": enGB,
  "zh-CN": zhCN,
  "fr-FR": fr,
  "ja-JP": ja,
  "ko-KR": ko,
  "es-ES": es,
  "tr-TR": tr,
  "pt-BR": pt,
};

export const landingLangStrings = {
  "en-GB": "en",
  "zh-CN": "ch",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "fr-FR": "fr",
  "es-ES": "es",
  "tr-TR": "tr",
  "pt-BR": "pt",
};

export const errorResponseTypes = {
  RestrictedTerritory: "RestrictedTerritory",
  UnsupportedTerritory: "UnsupportedTerritory",
  LimitExceededException: "LimitExceededException",
  UsernameExistsException: "UsernameExistsException",
  AliasExistsException: "AliasExistsException",
  CodeMismatchException: "CodeMismatchException",
  UserNotFoundException: "UserNotFoundException",
  InvalidPasswordException: "InvalidPasswordException",
  NotAuthorizedException: "NotAuthorizedException",
  SessionExpiredException: "SessionExpiredException",
  SessionUnavailableOfflineException: "SessionUnavailableOfflineException",
  SignedOutException: "SignedOutException",
  UserNotConfirmedException: "UserNotConfirmedException",
  IncorrectPaymentMethodForZoopBalanceFund:
    "IncorrectPaymentMethodForZoopBalanceFund",
  ZoopBalanceInsufficientFunds: "ZoopBalanceInsufficientFunds",
  ProfileNotCompleted: "Profile_not_completed",
  Exception: "Exception",
  RequestIsOpen: "RequestIsOpen",
  BadRequest: "BadRequest",
  InDroppingProcess: "InDroppingProcess",
  KYCRequired: "KYCRequired",
  NoEditionsLeft: "NoEditionsLeft",
  Error: "Error",
};

export const errorResponseMsgs = {
  PasswordAttemptsExceeded: "Password attempts exceeded",
  InvalidVerificationCode:
    "Invalid verification code provided, please try again.",
  LimitExceededTryLater: "Attempt limit exceeded, please try after some time.",
  IncorrectUsernameOrPassword: "Incorrect username or password.",
  PasswordExpired: "Password expired, please create a new one",
};

export const WITHDRAW_TYPE = {
  US: "US",
  IBAN_SUPPORTED: "IBAN_SUPPORTED",
  IBAN_NOT_SUPPORTED: "IBAN_NOT_SUPPORTED",
};

export const DISCORD_LINK = "https://discord.com/invite/zoopcards";

export const zoopPolicyHost = "https://policy.zoop.club";

export const zoopEmail = "support@zoop.club";

export const forgotPasswordSteps = {
  email: 1,
  code: 2,
  password: 3,
  success: "success",
};

export const PARTICIPATING_USERS_TYPES = {
  All: "all",
  inProgress: "inProgress",
  completed: "completed",
};

export const USERS_LOAD_LIMIT = 20;

export const FLOWS = {
  BUY_DROP: "user2iiRevenue-drop",
  BUY_MARKET_SELLER: "user2user-market",
  ADD_FUNDS: "user2balance-addFunds",
  WITHDRAW_FUNDS: "balance2user-withdrawFunds",
  SUBSCRIPTION: "user2influencer-subscription",
  DONATION: "user2influencer-donation",
  REFERRAL_PAYOUT: "iiRevenue2balance-refPayout",
};

export const PAYMENT_TYPE = {
  DEPOSIT: "deposit",
  WITHDRAW: "withdraw",
  MARKET: "market",
  DROP: "drop",
};

export const ACTIVITY_DEVICE_TYPES = {
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile",
};

export const MIN_ADD_FUNDS = 0.5;

export const BLOCK_REASON_KYC = "KYC_REQUIRED";
export const USER_BLOCKED = "UserIsBlocked";
export const USER_STATUS_BLOCKED = "blocked";

export const COMMENT_TEXT_LIMIT = 200;
export const POST_TEXT_LIMIT = 170;

export const REPLY_COMMENT_TEXT_LIMIT = 145;
export const FULL_COMMENT_TEXT_LIMIT = 4000;
export const SUCCESS_MSG_LIFETIME = 7000;

export const BUY_EDITION = "BUY_EDITION";

export const SECURITY_STATES = {
  default: 0,
  activityHistory: 1,
  resetPassword: 2,
  mfa: 3,
};
export const SUSCRIPTION_STATES = {
  default: 0,
};

export const HELP_STATES = {
  default: 0,
  faq: 1,
  chatWithSupport: 2,
};

export const POLICIES_STATES = {
  default: 0,
};

export const RESET_PASSWORD_STATES = {
  email: 0,
  code: 1,
  password: 2,
  success: 3,
};

export const BARTER_FILTER_TYPES = {
  none: "chooseCategory",
  industry: "industry",
  name: "name",
};

export const categoriesList = [
  "Fashion",
  "Health & Fitness",
  "Music",
  "Social Figure",
  "Sports",
  "Tech & Gaming",
  "Travel",
  "Beauty",
  "Business",
  "Foodie",
  "Lifestyle",
];

export const VIP_STATUS = {
  PENDING: "PENDING",
  NO: "NO",
  YES: "YES",
};

export const CARD_TYPES = {
  VISA: "VISA",
  MASTERCARD: "MASTERCARD",
};

export const isMobileCssBreakpoint = 1260;

export const MATCH_STATUSES = {
  pending: "pending",
  rejected: "rejected",
  cancelled: "cancelled",
  completed: "completed",
};

export const TOOLTIP_SHOWN = "shown";

export const CHANNELS = {
  DROPS: "drops",
  CARDS: "cards",
  POST: "post",
  POSTS: "posts",
  MODERATION: "moderation",
};

export const EVENTS = {
  PRICE_CHANGED: "price-changed",
  STATUS_CHANGED: "status-changed",
  BUY: "buy",
  DROP_BUY_SUCCESS: "DROP_BUY_SUCCESS",
  DROP_BUY_FAILED: "DROP_BUY_FAILED",
  COMMENT_CREATED: "comment-created",
  NEW_NOTIFICATION: "NEW_NOTIFICATION",
  WALLET: "WALLET",
  POST_CREATED: "post-created",
  POST_DELETED: "post-deleted",
  POST_UPDATED: "post-updated",
  POST_REVIEWED: "post-reviewed",
  POST_APPROVED_ALL: "post-approved-all",
  POST_REJECTED_ALL: "post-rejected-all",
  CHALLENGE_STAGE_COMPLETED: "CHALLENGE_STAGE_COMPLETED",
  INFLUNCER_SUBSCRIPTION_UPDATED: "INFLUNCER_SUBSCRIPTION_UPDATED",
  INFLUNCER_SUBSCRIPTION_DELETED: "INFLUNCER_SUBSCRIPTION_DELETED",
  REWARD_BALANCE: "REWARD_BALANCE",
  SELL_ORDER_EXECUTED: "SELL_ORDER_EXECUTED",
  BUY_ORDER_EXECUTED: "BUY_ORDER_EXECUTED",
  COMMENT_DELETED: "comment-deleted",
  COMMENT_UPDATED: "comment-updated",
  MODERATION_COMMENT_UPDATED: "moderation-comment-updated",
  PAYMENT_3DS_VERIFICATION: "PAYMENT_3DS_VERIFICATION",
  BUY_ORDER_FAILED: "BUY_ORDER_FAILED",
  CHATS: "chats",
  CHATS_UNREAD_COUNT: "chats-unread-count",
  CHAT_TYPING: "chat-typing",
  CHATS_MESSAGE_SEEN: "chats-message-seen",
  CHATS_DELETED: "chats-deleted",
  CHATS_DELETED_RESTORE: "chats-deleted-restore",
  REWARD_MESSAGING: "reward-messaging",
  REWARD_DELIVERED: "reward-delivered",
  CLUB_CHALLENGES: "club-challenges",
  CHALLENGES: "challenges",
};

export const TRANSFERS_LOAD_LIMIT = 25;

export const INFLUENCERS_LOAD_LIMIT = 40;
export const PAYMENT_TYPE_BTC = "btc";
export const PAYMENT_TYPE_PAYPAL = "paypal";
export const PAYMENT_TYPE_STRIPE = "stripe";
export const PAYMENT_TYPE_ZOOP_WALLET = "wallet";
export const PAYMENT_TYPE_USDC = "USDC";
export const PAYMENT_TYPE_CREDIT_CARD = "Credit Card";

export const PAYMENT_METHODS = [
  {
    type: PAYMENT_TYPE_BTC,
    id: "OPENNODE",
    name: "Bitcoin",
  },
  { type: PAYMENT_TYPE_STRIPE, id: "STRIPE", name: "Credit/Debit Card" },
  { type: PAYMENT_TYPE_PAYPAL, id: "PAYPAL", name: "Paypal" },
];
export const STATUS_FAILURE = "failed";
export const SOCIAL_MEDIA_ENUM = {
  Twitter: Symbol("twitter"),
  Facebook: Symbol("facebook"),
  Instagram: Symbol("instagram"),
  Tiktok: Symbol("tiktok"),
  Website: Symbol("website"),
  Youtube: Symbol("youtube"),
  Telegram: Symbol("telegram"),
};
export const SOCIAL_LINKS = [
  {
    socialPlatformId: 2,
    prependLink: "https://twitter.com/",
    code: SOCIAL_MEDIA_ENUM.Twitter,
  },
  {
    socialPlatformId: 3,
    prependLink: "https://facebook.com/",
    code: SOCIAL_MEDIA_ENUM.Facebook,
  },
  {
    socialPlatformId: 4,
    prependLink: "https://www.instagram.com/",
    code: SOCIAL_MEDIA_ENUM.Instagram,
  },
  {
    socialPlatformId: 5,
    prependLink: "https://www.tiktok.com/@",
    code: SOCIAL_MEDIA_ENUM.Tiktok,
  },
  {
    socialPlatformId: 6,
    prependLink: "https://",
    code: SOCIAL_MEDIA_ENUM.Website,
  },
  {
    socialPlatformId: 7,
    prependLink: "https://youtube.com/",
    code: SOCIAL_MEDIA_ENUM.Youtube,
  },
  {
    socialPlatformId: 8,
    prependLink: "https://t.me/",
    code: SOCIAL_MEDIA_ENUM.Telegram,
  },
];

export const PATHS = {
  BLOCKED: "/blocked",
  HOME: "/home",
  DROPS: "/marketplace",
  DROPS_TAB: "/marketplace/tab/:activeTab",
  SEARCH: "/search",
  SIGN_UP: "/sign-up",
  SIGN_UP_CODE: "/sign-up/:code",
  LOGIN: "/login",
  LOGIN_CODE: "/login/:code",
  FORGOT_PASSWORD: "/forgot-password",
  COMPLETE_PROFILE: "/complete-profile",
  EMAIL_VERIFICATION: "/email-verification",
  DISCOVER_INFLUENCER_ID: "/discover/influencer/:id",
  FOLLOWERS: "/discover/influencer/:id/followers",
  USERNAME: "/:username",
  USERNAME_FOLLOWERS: "/:username/followers",
  CARD_ID: "/product/:id",
  DISCOVER: "/discover",
  DISCOVER_EDITION_SELL: "/discover/product/:id/sell",
  DISCOVER_PASS_BUY: "/discover/product/:id/buy",
  CHALLENGES: "/challenges",
  CHALLENGES_CATEGORY: "/challenges/category/:category",
  CHALLENGES_ID: "/challenges/:id",
  REFERRALS: "/referrals",
  PROFILE: "/profile",
  NOTIFICATIONS: "/notifications",
  POST_ID: "post/:postId",
  TAB_ID: "tab/:activeTab",
  TRANSACTIONS: "/transactions",
  // Settings routes
  SETTINGS: "/settings",
  SETTINGS_ACCOUNTS: "/settings/account",
  SETTINGS_ACCOUNTS_USERNAME: "/settings/account/username",
  SETTINGS_ACCOUNTS_PERSONAL_INFORMATION:
    "/settings/account/personal-information",
  SETTINGS_ACCOUNTS_EMAIL: "/settings/account/email",
  SETTINGS_ACCOUNTS_BLOCKED: "/settings/account/blocked-accounts",

  SETTINGS_RESOURCES_HELP: "/settings/resources/help",
  SETTINGS_RESOURCES_POLICIES: "/settings/resources/policies",
  SETTINGS_RESOURCES_DATA_MANAGEMENT: "/settings/resources/data-management",

  SETTINGS_SECURITY_ACTIVITY_HISTORY: "/settings/security/activity-history",
  SETTINGS_SECURITY_RESET_PASSWORD: "/settings/security/reset-password",
  SETTINGS_SECURITY_TWO_FACTOR_AUTHENTICATION:
    "/settings/security/two-factor-authentication",

  SETTINGS_SECURITY_ACTIVITY_TRANSACTIONS:
    "/settings/activity/activity-transactions",

  SETTINGS_SECURITY: "/settings/security",
  SETTINGS_TRANSFERS: "/settings/transfers",
  SETTINGS_HELP: "/settings/help",
  SETTINGS_EMAIL_NOTIFICATIONS: "/settings/notifications/email-notifications",

  LAST_SEGMENT_EMAIL_NOTIFICATIONS: "email-notifications",
  SETTINGS_POLICIES: "/settings/Index",
  BADGES: "/profile/badges",
  WALLET_WITHDRAW_TYPE_USDC: "/wallet/withdraw/type/usdc",
  WALLET_WITHDRAW: "/wallet/withdraw",
  WALLET_ADD_FUNDS: "/wallet/addfunds",
  ADD_CARD: "/add-card",
  DROPS_ID_BUY_SUCCESS: "/marketplace/:id/buy/success",
  DROPS_ID_SUCCESS: "/marketplace/:id/success",
  DROPS_ID_BUY: "/marketplace/:id/buy",
  PHASE_ID: "/phase/:id",
  CHAT: "/chat",
  CHAT_DIRECT: "/chat/:userId",
  ADD_FOUNDS_USDC: "/wallet/addfunds/usdc",
  ADD_FOUNDS_USDC_NETWORK_ID: "/wallet/addfunds/usdc/:networkId",
  ADD_FOUNDS_USDC_AMOUNT: "/wallet/addfunds/amount",
  RESALE_CARDS: "/resale-cards",
  LIMITED_EDITION_CARDS: "/limited-edition-cards",
  CLUB_CHALLENGES_ID: "/club-challenges/:id",
  GOOGLE_SIGNUP: "/google-signup",
  MEETING: "/meeting",
  MEETING_ID: "/meeting/:meetingId",
  REWARDS: "/rewards",
  WALLET: "/wallet",
  CLUBS: "/clubs",
  CLUBS_ID: "/clubs/:id",
  PAGE_404: "/404",
  PAGE_500: "/500",
  PAGE_503: "/503",
  REFERRALS_CREATORS: "/referrals/creators",
  PAYMENTS_RETURN: "payments/return",
  PAYMENTS_REFRESH: "payments/refresh",
  TELEGRAM_ONBOARDING_SPLASH: "/telegram-splash",
  TELEGRAM_SLIDE_EARN: "/telegram-slide-earn",
  TELEGRAM_SLIDE_POINTS: "/telegram-slide-points",
  TELEGRAM_SLIDE_FRIENDS: "/telegram-slide-friends",
  TELEGRAM_SLIDE_AIRDROP: "/telegram-slide-airdrop",
  TELEGRAM_ONBOARDING_GIFT: "/telegram-onboarding-gift",
  AIRDROP: "/airdrop",
};

export const ROLES = {
  MODERATOR: "moderator",
  CUSTOMER: "customer",
};
export const COOKIE = "cookie";
export const COOKIE_ACCEPTED = "accepted";
export const SNACKBAR_TYPE = {
  COOKIE: "COOKIE",
};

export const ANALYTICS_EVENTS = {
  ADD_FUNDS: "add_funds",
  CLICK_ADD_FUNDS: "click_add_funds",
  SIGN_UP: "sign_up",
  LOGIN: "login",
  LOGOUT: "logout",
  ADD_TO_CART: "add_to_cart",
  ADD_PAYMENT_INFO: "add_payment_info",
  PAYMENT_FAILED: "payment_failed",
  PURCHASE: "purchase",
  PAYMENT_PROGRESS: "payment_progress",
  SUBSCRIBE_CELEBRITY: "subscribe_celebrity",
  APP_CLICK_TOP_MENU: "app_click_top_menu",
  VIEW_CELEBRITY: "view_celebrity",
  SHARE: "share",
  CLICK_INVITE_FRIEND: "click_invite_friend",
  CLICK_INVITE_CELEBRITY: "click_invite_celebrity",
  WITHDRAW: "withdraw",
  VIEW_PROFILE: "view_profile",
};

export const REASONS_FOR_REPORTS = [
  { label: "Spam", name: "spam", id: 1 },
  { label: "Bullying or harassment", name: "bullying_or_harassment", id: 2 },
  { label: "Scams and fraud", name: "scams_and_fraud", id: 3 },
  { label: "Hate speech", name: "hate_speech", id: 4 },
];
export const TYPES_OF_PROOF = [
  { label: "Link", name: "LINK", id: 1 },
  { label: "Screenshot", name: "SCREENSHOT", id: 2 },
];

export const EMULATE = {
  INFLUENCER_EMULATE_ID: "emulate-influencer-id",
  INFLUENCER_EMULATE_PARAM: "emulateInfluencerId",
  LOCAL_STORAGE_ID: "emulate-id",
  HEADER: "emulate-user-id",
  INFLUENCER_HEADER: "emulate-influencer-id",
  ACCESS_ERROR: "Emulation mode detected. The action will not proceed.",
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb
export const MAX_FILE_HEIGHT = 5000;
export const MAX_ALL_FILES_SIZE = 500 * 1024 * 1024; // 500mb

export const MEDIA_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  TEXT: "text",
  FILE: "file",
};

export const POST_TYPES = {
  AUDIO: "audio",
  VIDEO: "video",
  IMAGE: "image",
  TEXT: "text",
  SYSTEM_NEW_CARD: "system-new-card",
};

export const AUDIO_FORMAT = {
  MPEG: "mpeg",
  MP3: "mp3",
};

export const MEDIA_URLS_PROP = {
  imageUrl: "imageUrl",
  audioUrl: "audioUrl",
  videoUrl: "videoUrl",
};

export const VIDEO_TYPES = {
  MP4: "video/mp4",
  MOV: "video/quicktime",
  AVI: "video/avi",
};

export const IMAGE_TYPES = {
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
};

export const AUDIO_TYPES = {
  MPEG: "audio/mpeg",
  MP3: "audio/mp3",
};

export const IMAGE_FORMAT = {
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
};

export const VIDEO_FORMAT = {
  MP4: "mp4",
  AVI: "avi",
  MOV: "mov",
  QUICKTIME: "quicktime",
};

export const POST_STATUS = {
  PUBLISHED: "published",
  PENDING_REVIEW: "pending_review",
  REJECTED: "rejected",
};

export const YOUTUBE_REGEX =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|shorts\/|live\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export const getCategories = ({ t }) => ({
  Entertainment: t("default.entertainment"),
  "Tech &Gaming": t("default.techGaming"),
  Music: t("default.music"),
  "Social Figure": t("default.socialFigure"),
  Fashion: t("default.fashion"),
  Sport: t("default.sport"),
  "Health & Fitness": t("default.healthFitness"),
  Travel: t("default.travel"),
  Beauty: t("default.beauty"),
  Business: t("default.business"),
});

export const TMP_USER_CHAT_ID = "-1";
export const CHATS_TYPE = {
  MY: "my",
  REQUESTS: "requests",
};

export const REWARDS_ACTION_TYPE = {
  HIDDEN_CONTENT: "content",
  PREMIUM_CHAT: "chat",
  FOLLOW_BACK: "followBack",
  VIDEO_CALL: "call",
  GROUP_VIDEO_CHAT: "groupCall",
  // LIVESTREAM: "livestream",
  // CUSTOM: "custom",
};
export const REWARDS = {
  [REWARDS_ACTION_TYPE.HIDDEN_CONTENT]: { label: "default.hiddenContent" },
  [REWARDS_ACTION_TYPE.PREMIUM_CHAT]: { label: "default.premiumChat" },
  [REWARDS_ACTION_TYPE.FOLLOW_BACK]: { label: "default.followBack" },
  [REWARDS_ACTION_TYPE.VIDEO_CALL]: { label: "default.videoCall" },
  [REWARDS_ACTION_TYPE.GROUP_VIDEO_CHAT]: { label: "default.groupCall" },
};
export const VIDEO_DATA_ACTIVE = "active";
export const VIDEO_DATA_NOT_ACTIVE = "";

export const CHAT_CATEGORIES = {
  PREMIUM: "premium",
  GENERAL: "general",
};

export const REWARD_ORDER_STATUS = {
  OPEN: "open",
  PENDING_REPLY: "pendingReply",
  AWAITING: "awaiting",
  DELIVERED: "delivered",
  FAILED: "failed",
};

export const REWARD_STATUS = {
  UNLOCKED: "unlocked",
  LOCKED: "locked",
};

export const INFLUENCER_ACTIVE_ID_STORAGE_KEY =
  "influencer_active_id_storage_key";

export const CHALLENGE_CATEGORIES = {
  COLLECTION: "collection",
  MONEY: "money",
  SOCIAL: "social",
  OTHER: "other",
};

export const FEED_POSTS_TYPES = {
  FOR_YOU: "forYou",
  CLUBS: "clubs",
  FRIENDS: "friends",
};

export const TUTORIALS_PROPS = {
  CREATORS_TUTORIAL_1: "creatorsTutorial1",
  CREATOR_MULTIPLE_TUTORIAL_1: "creatorMultipleTutorial1",
  CREATOR_MULTIPLE_TUTORIAL_2: "creatorMultipleTutorial2",
  GROUPS_TUTORIAL_1: "groupsTutorial1",
  GROUP_ID_TUTORIAL_1: "groupIdTutorial1",
  MARKETPLACE_TUTORIAL_1: "marketplaceTutorial1",
};

export const DEFAULT_TITLE_TAG = "Zoop™";
