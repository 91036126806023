import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as USER_API from "services/userApi";
const limit = 20;

export const fetchQuestsAndAchievements = createAsyncThunk(
  "user/rewards/questsAndAchievements",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.getQuestsAndAchievements({ ...params });
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

export const fetchRewards = createAsyncThunk(
  "user/rewards",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.getRewards({ ...params });
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);
export const fetchMoreRewards = createAsyncThunk(
  "user/rewards/more",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.getRewards({ ...params });
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);
const initialState = {
  loading: false,
  entities: [],
  quests: {
    loading: false,
    entities: {},
  },
  hasMore: true,
};

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestsAndAchievements.pending, (state, action) => {
        state.quests.loading = true;
      })
      .addCase(fetchQuestsAndAchievements.fulfilled, (state, action) => {
        state.quests.loading = false;
        state.quests.entities = action.payload || {};
      })
      .addCase(fetchQuestsAndAchievements.rejected, (state, action) => {
        state.quests.loading = false;
      })
      .addCase(fetchRewards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.hasMore =
          action.payload?.length >= (action.meta?.arg?.limit || limit);
        state.entities = action.payload || [];
      })
      .addCase(fetchRewards.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchMoreRewards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchMoreRewards.fulfilled, (state, action) => {
        state.loading = false;
        state.hasMore =
          action.payload?.length >= (action.meta?.arg?.limit || limit);
        state.entities = [...state.entities, ...(action.payload || [])];
      })
      .addCase(fetchMoreRewards.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default rewardsSlice.reducer;
